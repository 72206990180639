export default function VideoPlayer({ video }) {
  return (
    <div className="videoPlayer">
      <h3>{video.title}</h3>
      <iframe
        src={video.url}
        width="560"
        height="314"
        style={{ overflow: "hidden" }}
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowFullScreen="true"
        title="Colostrum - kompendium wiedzy"
      ></iframe>
    </div>
  );
}
