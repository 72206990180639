export default function VideoPlayer({ video }) {
  return (
    <div className="videoPlayer">
      <h3>{video.title}</h3>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        <iframe
          src={video.url}
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
          title="Colostrum - wykład I"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
}
