import GridSelect from "../../components/GridSelect";
import VideoPlayer from "../../components/Layout/VideoPlayer";
import VideoPlayerVimeo from "../../components/Layout/VideoPlayerVimeo";

export default function Colostrum() {
  const categories = ["suplementy"];

  const vid1 = { url: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDr.Karwowski%2Fvideos%2F1586834828360189%2F&show_text=false&width=560&t=0", title: "Colostrum z Immunoglobulinami" };
  const vid2 = { url: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDr.Karwowski%2Fvideos%2F774796337625557%2F&show_text=false&width=560&t=0", title: "Colostrum z Aminokwasami" };
  const vid3 = { url: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDr.Karwowski%2Fvideos%2F1273206333129328%2F&show_text=false&width=560&t=0", title: "Colostrum, jesień 2021 - krótko i na temat" };
  const vid4 = { url: "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FDr.Karwowski%2Fvideos%2F1218265752893001%2F&show_text=false&width=560&t=0", title: "Colostrum - powtórka ..." };
  const vid5 = { url: "https://player.vimeo.com/video/653356243?h=47c39ecf1f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", title: "Colostrum - wykład I" };
  const vid6 = { url: "https://player.vimeo.com/video/653356251?h=df18ca6d07&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", title: "Colostrum - wykład II" };
  const vid7 = { url: "https://player.vimeo.com/video/653356255?h=13a36aaff3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479", title: "Colostrum - wykład III" };

  return (
    <div className="colostrum">
      <h2 className="title-colostrum">Colostrum - kompendium wiedzy</h2>
      <h3>Czyli wszystko co musisz wiedzieć o naszym Colostrum i jego odmianach.</h3>
      <p>
        Mozesz tutaj obejrzeć obszerne wykłady <strong>Dr Wojciecha Karwowskiego</strong> na temat Colostrum. Czym jest, jak działa, komu może pomóc. Jeśli chcesz dowiedzieć się więcej o naszym przebojowym produkcie, to to jest najlepsze miejsce.
      </p>
      <div className="videoWrapper">
        <VideoPlayer video={vid1} />
        <VideoPlayer video={vid2} />
        <VideoPlayer video={vid3} />
        <VideoPlayer video={vid4} />
        <VideoPlayerVimeo video={vid5} />
        <VideoPlayerVimeo video={vid6} />
        <VideoPlayerVimeo video={vid7} />
      </div>
      <div className="grid-colostrum">
        <h2>Tutaj możesz kupić nasze Colostrum</h2>
        <GridSelect categories={categories} />
      </div>
    </div>
  );
}
